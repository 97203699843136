// import axios from "axios";
import { hanatourApi } from '../main.js';

export default class PhotoService {
    getImages() {
        return hanatourApi
            .get("assets/demo/data/photos.json")
            .then(res => res.data.data);
    }
}
